<template>
    <div class="center">
        <img src="@/assets/avatar.png" width="128">
        <div class="child">GenAi</div>
        <div class="child"><a href="/invite">Invite</a></div>
        <div class="child"><a href="/support">Support server</a></div>
    </div>
</template>

<style>
.center {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;

    height: 98vh;

    font-family: 'Montserrat', sans-serif;
    font-size: xx-large;
}

.child {
    text-align: center;
}
</style>
