import HomeView from './views/HomeView.vue'
import PremiumView from './views/PremiumView.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/', component: HomeView },
    { path: '/premium', component: PremiumView },
    { path: '/invite', beforeEnter: () => window.location.href = 'https://discord.com/oauth2/authorize?client_id=974297735559806986&scope=bot+applications.commands&permissions=378880' },
    { path: '/boosty', beforeEnter: () => window.location.href = 'https://boosty.to/bit0r1n' },
    { path: '/support', beforeEnter: () => window.location.href = 'https://discord.gg/qdCy7cEN7f' },
    { path: '/l10n', beforeEnter: () => window.location.href = 'https://crowdin.com/project/genai' },
    { path: '/tos', beforeEnter: () => window.location.href = '/tos.txt' },
    { path: '/privacy', beforeEnter: () => window.location.href = '/privacy.txt' }
]

const router = createRouter({
    routes,
    history: createWebHistory()
});

export default router;
