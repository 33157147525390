<template>
    <div class="container">
		<div>CHOOSE PLATFORM</div>
		<a href="https://patreon.com/bit0r1n"><img class="elem" src="@/assets/patreon.png" width="200"></a>
		<a href="https://boosty.to/bit0r1n"><img class="elem" src="@/assets/boosty.svg" width="200"></a>
		<div>ВЫБЕРИ ПЛАТФОРМУ</div>
	</div>
</template>

<style>
.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.elem { transition: all .2s ease-in-out; }
.elem:hover { transform: scale(1.3); }

html, body {
	height: 100%;
	font-family: 'Trebuchet MS', sans-serif;
	font-weight: bold;
}
</style>